import React from "react";
import "./WatchLoader.css";
const WatchLoader = () => {
  return (
    <div>
      <span className="watchLoader"></span>
    </div>
  );
};

export default WatchLoader;
